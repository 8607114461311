<template>
  <!-- 用户权限修改界面 -->
  <div class="col-sm-10 pr-0 " style="height: 500px;overflow-y: auto;">
    <table class="table border-bottom table-hover">
      <tbody>
        <tr v-for="(v, i) in modules" :key="i" v-if="v">
          <td>
            <div class="form-check mr-2 ">
              <input type="checkbox" v-model="v.has" :id="'defaultCheck'+i" class="form-check-input"
                @change="updateEvent" /><label :for="'defaultCheck'+i" class="form-check-label">{{
            v.name
          }}</label>
            </div>
          </td>
          <td>
            <div class="form-check mr-2 ">
              <input type="radio" :name="i" :value="3" v-model="v.num" checked="checked" class="form-check-input"
                @change="updateEvent" /><label for="exampleRadios1" class="form-check-label">全部 </label>
            </div>
          </td>
          <td>
            <div class="form-check mr-2 ">
              <input type="radio" :name="i" :value="2" v-model="v.num" checked="checked" class="form-check-input"
                @change="updateEvent" /><label for="exampleRadios1" class="form-check-label">写入 </label>
            </div>
          </td>
          <td>
            <div class="form-check mr-2">
              <input type="radio" :name="i" :value="1" v-model="v.num" class="form-check-input"
                @change="updateEvent" /><label for="exampleRadio1" class="form-check-label">只读</label>
            </div>
          </td>
        </tr>
      </tbody>


    </table>


    <!-- <div class="flexList" v-for="(v, i) in modules" :key="i">
      <div class="form-check mr-2 w-20">
        <input type="checkbox" v-model="v.has" id="defaultCheck1" class="form-check-input"
          @change="updateEvent" /><label for="defaultCheck1" class="form-check-label">{{
          v.name
        }}</label>
      </div>
      <div class="form-check mr-2 w-10">
        <input type="radio" :name="i" :value="3" v-model="v.num" checked="checked" class="form-check-input"
          @change="updateEvent" /><label for="exampleRadios1" class="form-check-label">全部 </label>
      </div>
      <div class="form-check mr-2 w-10">
        <input type="radio" :name="i" :value="2" v-model="v.num" checked="checked" class="form-check-input"
          @change="updateEvent" /><label for="exampleRadios1" class="form-check-label">写入 </label>
      </div>
      <div class="form-check mr-2">
        <input type="radio" :name="i" :value="1" v-model="v.num" class="form-check-input" @change="updateEvent" /><label
          for="exampleRadio1" class="form-check-label">只读</label>
      </div>
    </div> -->
  </div>
</template>

<script>
  import {getUserModules} from "@/api/admin/user/userAdmin";

  export default {
    name: "ErsAdminUserPromission",
    model: {
      prop: "promission",
      event: "input",
    },
    props: {
      promission: {
        default: "",
      },
      xm: String,
    },
    data() {
      return {
        modules: [],
      };
    },

    mounted() {
      this.getUserModules()
    },

    methods: {
      initMenu(){
        let pro = this.promission.split("");
        this.modules.forEach((item) => {
          let num = pro[item.index-1];
          if (!num || num==0) {
            item.has = false;
            item.num = 0;

          } else {
            item.has = true;
            item.num = num;
          }
        });
        this.$forceUpdate()
      },
      getUserModules(){
        this.modules = []
        getUserModules().then(res=>{
            if (res.status){
              let data = res.data
              let list = []
              let parent = data.filter(x=>{
                return !x.name.includes("_")
              })
              parent.forEach(x=>{
                list.push(x)
                list.push(...data.filter(y=>{
                  return y.name.includes(x.name+"_")
                }))
              })
              list.forEach((item,index)=>{
                this.modules[index] = {
                  name: item.title,
                  has: true,
                  num: 3,
                  index: item.index
                }
              })

              this.initMenu()
            }
        })
      },


      updateEvent() {
        this.$emit("input", this.getPerStr());
      },
      getPerStr() {
        let pro = [];
        for(var i=0;i<100;i++) {
          let flag = false
          this.modules.forEach((item) => {
            if((i+1) == item.index) {
              flag = true
              pro.push(item.has ? item.num : 0);
            }
          });
          // 未找到默认为0
          if(!flag) {
            pro.push(0);
          }
        }
        return pro.join("");
      },
    },
  };
</script>

<style lang="scss" scoped></style>
