<template>
  <div class="yz-select">
    <div v-for="(bound, v) in bounds" :key="v" style="margin-right: 10px">
      <yz-select
        v-if="dictNext[v]"
        :dict="dictNext[v]"
        :bound="bound"
        v-model="form"
        :readOnly="readOnly"
        @change="evChange($event, v + 1)"
      >
      </yz-select>
    </div>
  </div>
</template>

<script>
import yzSelect from "./yzSelect.vue";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    dict: Array,
    form: Object,
    bounds: Array,
    readOnly:{
      default: false
    }
  },
  components: {
    yzSelect,
  },
  data() {
    return {
      dictNext: [],
    };
  },
  methods: {
    evChange(e, i) {
      this.dictNext[i] = e.children;
      this.$forceUpdate();
      this.$emit("input", this.form);
    },
    reload(){
      let c = JSON.parse(JSON.stringify(this.dict));
      this.dictNext = []
      for (let i = 0; i < this.bounds.length; i++) {
        this.dictNext.push(c);
        if (!c){
          break;
        }
        let cTemp = c.find((item) => {
          return this.form[this.bounds[i][0]] == item["value"];
        });
        c = cTemp ? cTemp.children : c[0].children;
      }
    }
  },
  mounted() {

  },
  watch:{
    dict: {
      deep:true,
      immediate: true,
      handler(){
        if (this.dict){
          this.reload()
        }
      }
    }
  }
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
.yz-select {
  display: flex;
  width: 100%;
}
</style>
