import { getRequest, postRequest, putRequest, deleteRequest, patchRequest } from "@/libs/axios";


// 查询机构用户
export const getUserAdmin = (yhlb, params) => {
    delete params['total']
    return getRequest(`/yethan/UserManagement/listPage/${yhlb}`, params);
};
export const addUserAdmin = (params) => {
    return postRequest("/yethan/UserManagement", params);
}

export const deleteUserAdmin = (mid) => {
    return deleteRequest(`/yethan/UserManagement/${mid}`);
}

export const updateUserAdminSfsd = (mid, sfsd) => {
    return patchRequest(`/yethan/UserManagement/${mid}/sfsd`, { sfsd: sfsd });
}

export const updateUserAdmin = (yhzh, params) => {
    return putRequest(`/yethan/UserManagement/info/${yhzh}`, params);
}

export const getUserAdminDetails = (mid) => {
    return getRequest(`/yethan/UserManagement/${mid}`);
}

export const resetLoginTimes = (mid) => {
    return patchRequest(`/yethan/UserManagement/${mid}/cwcs`, {});
}
export const getUserModules = () => {
    return getRequest(`/yethan/public/dict/userModules`, {});
}

